@import "./PulpFont/stylesheet.css";
@import "./colors.scss";

#banner-wrapper {
  padding: 0px 35px;
}

#banner-wrapper .Index-page-content {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #222854;
  color: $white;
  border-radius: 25px;
  padding: 17px;
  background-image: radial-gradient(
    circle farthest-corner at 50% 50%,
    rgba(62, 56, 127, 0.45),
    transparent
  );
  box-shadow: 0 20px 44px 0 rgba(27, 36, 72, 0.3);
}

.banner-content {
  text-align: center;

  .banner-description {
    max-width: 900px;
    margin: 20px auto 30px;
  }

  h1 {
    font-size: 55px;
    line-height: 72px;
    font-weight: 700;
    color: $white;
  }

  p {
    font-size: 28px;
    font-weight: 500px;
    line-height: 36px;
    color: #d6dbf0;
  }
}

.banner-btn {
  margin: 10px;
  text-transform: uppercase;
  padding: 14px 18px;
  color: $white;
  background-color: $purple;
  border: 2px solid $purple;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 300;
  transition: all ease-in-out 0.2s;
  cursor: pointer;

  &.get-started:hover {
    background-color: $purple-70;
  }

  &.install {
    background-color: transparent;
  }

  &.install:hover {
    background-color: $white;
    color: $purple;
  }
}

@media only screen and (max-width: 770px),
  only screen and (max-device-width: 770px) {
  #banner-wrapper {
    h1 {
      font-size: 28px;
      line-height: 31px;
    }

    p {
      font-size: 20px;
      line-height: 26px;
    }
  }
}
