@import "./PulpFont/stylesheet.css";
@import "./colors.scss";

$hamburger-layer-width: 17px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 3px;
$hamburger-active-hover-opacity: 1;
@import "node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

@import "./headerStyles.scss";
@import "./bannerStyles.scss";
@import "./companiesUsingMeteorStyles.scss";
@import "./meteorIntegratesWithStyles.scss";
@import "./whyMeteorStyles.scss";
@import "./meteorCloudStyles.scss";
@import "./builtWithMeteorStyles.scss";
@import "./openSourceStyles.scss";
@import "./footerStyles.scss";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Pulp Display";
  //outline: 1px solid red;
}

a:-webkit-any-link,
a:-webkit-any-link:active {
  text-decoration: none;
  color: $black;
}

h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  color: #1b2448;
}

h2 {
  font-size: 32px;
  font-weight: 300;
  line-height: 42px;
  color: #1b2448;
}

h3 {
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
  color: #8d91a3;
}
